/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
  title: "Robin Haider",
  logo_name: "RobinHaider",
  nickname: "Software Engineer",
  subTitle:
    "A passionate individual who always thrive to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1g6LewlwEK1owGuZYpRta6i7TgkCAQZlU/view?usp=sharing",
  portfolio_repository:
    "https://github.com/RobinHaider/dotnet-starter-clean-architechture",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/RobinHaider",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/haider-ali-robin/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCQa5P7CCCPQOm9ZyNmlar4w",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:hello@robinhaider.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/robin_haider",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  {
    name: "Instagram",
    link: "https://www.instagram.com/robinhaider/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
  {
    name: "Codepen",
    link: "https://codepen.io/RobinHaider/",
    fontAwesomeIcon: "fa-codepen", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Backend Development",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready RESTful API with ASP.NET Core, Docker and Kubernetes for various use cases",
        "⚡ Experience of working with  JWT Authentication, Auto Mapper, Mapster, Dependency Injection, Swagger Documentation, Redis, Stripe etc.",
        "⚡ Writing a complex Query or Stored procedure with either LINQ or SQL for data manupulation",
        "⚡ Use Clean Architecture, Domain Driven Design, CQRS, Mediator Pattern, Domain Events, AggregateRoot, AuditableEntity etc. ",
        "⚡ Worked with pattern like Repository Pattern, Specification Pattern, Unit of Work Pattern",
      ],
      softwareSkills: [
        {
          skillName: "C#",
          fontAwesomeClassname: "logos-c-sharp",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "ASP.NET Core",
          fontAwesomeClassname: "simple-icons:dot-net",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "ASP.NET MVC",
          fontAwesomeClassname: "logos-dotnet",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Web API",
          fontAwesomeClassname: "mdi-api",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "REST",
          fontAwesomeClassname: "vscode-icons:file-type-rest",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Entity Framework Core",
          fontAwesomeClassname: "twemoji-letter-e",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "SQL",
          fontAwesomeClassname: "vscode-icons:file-type-plsql",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Microsoft SQL Server",
          fontAwesomeClassname: "simple-icons:microsoftsqlserver",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "LINQ",
          fontAwesomeClassname: "file-icons:linqpad",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "vscode-icons:folder-type-mongodb",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Node",
          fontAwesomeClassname: "vscode-icons:folder-type-node",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Frontend Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using Angular and React",
        "⚡ Creating reuseable component, Design and architect  modular apps with lazy loading, Use RxJs to declaratively manage data, Securing app with JWT Authentication, Connecting with backend",
        "⚡ Building amazing apps with modern tools like Redux, RxJs and React Query",
      ],
      softwareSkills: [
        {
          skillName: "React",
          fontAwesomeClassname: "logos:react",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "logos-angular-icon",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Next JS",
          fontAwesomeClassname: "logos-nextjs-icon",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Micro-Frontend",
          fontAwesomeClassname: "mdi:alpha-m-circle",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "logos:redux",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "React Query",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Angular Material",
          fontAwesomeClassname: "noto-v1:circled-m",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Typescript",
          fontAwesomeClassname: "logos-typescript-icon",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Rxjs",
          fontAwesomeClassname: "emojione-letter-r",
          style: {
            color: "#E34F26",
          },
        },

        {
          skillName: "NgRx",
          fontAwesomeClassname: "emojione-letter-n",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "logos-bootstrap",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Flexbox",
          fontAwesomeClassname: "logos-flexible-gs",
          style: {
            color: "#663399",
          },
        },
      ],
    },
    // {
    //   title: "Cloud Infra-Architecture",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Experience working on multiple cloud platforms",
    //     "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
    //     "⚡ Deploying deep learning models on cloud to use on mobile devices",
    //     "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "GCP",
    //       fontAwesomeClassname: "simple-icons:googlecloud",
    //       style: {
    //         color: "#4285F4",
    //       },
    //     },
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Azure",
    //       fontAwesomeClassname: "simple-icons:microsoftazure",
    //       style: {
    //         color: "#0089D6",
    //       },
    //     },
    //     {
    //       skillName: "Firebase",
    //       fontAwesomeClassname: "simple-icons:firebase",
    //       style: {
    //         color: "#FFCA28",
    //       },
    //     },
    //     {
    //       skillName: "PostgreSQL",
    //       fontAwesomeClassname: "simple-icons:postgresql",
    //       style: {
    //         color: "#336791",
    //       },
    //     },
    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     {
    //       skillName: "Kubernetes",
    //       fontAwesomeClassname: "simple-icons:kubernetes",
    //       style: {
    //         color: "#326CE5",
    //       },
    //     },
    //   ],
    // },
    {
      title: "Development Tools",
      fileName: "DesignImg",
      skills: [
        "⚡ Writing clean code or debug with Visual Studio and Visual Studio Code",
        "⚡ Writing command on terminal to build apps faster",
        "⚡ Using git to tracking changes or go back to any previous work and Postman to test API",
      ],
      softwareSkills: [
        {
          skillName: "Visul Studio",
          fontAwesomeClassname: "vscode-icons:folder-type-vs",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Visul Studio Code",
          fontAwesomeClassname: "vscode-icons:file-type-vscode3",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "logos-git-icon",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "fa-brands:github-square",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Terminal",
          fontAwesomeClassname: "bi-terminal-fill",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Chrome Browser",
          fontAwesomeClassname: "teenyicons-chrome-outline",
          style: {
            color: "#000000",
          },
        },

        {
          skillName: "Postman",
          fontAwesomeClassname: "logos-postman",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#FFA500",
      },
      profileLink: "https://leetcode.com/RobinHaider/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/robinhaider69",
    },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "BASIS Institute of Technology & Management (BITM)",
      subtitle: " Web App Development With ASP.NET",
      logo_path: "bitm.png",
      alt_name: "BITM",
      duration: "2017",
      descriptions: [
        "⚡ I have studied Object Oriented Programming in C#",
        "⚡ Working with ASP.NET MVC",
        "⚡ Working with Entity Framework.",
        "⚡ I was selected as a Student of The Batch for my outstanding performance.",
      ],
      website_link:
        "https://drive.google.com/file/d/1BH9SmXg3aAo2GYNyOXk5-UQrCjvHkw9a/view?usp=sharing",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Microservices Architecture and Implementation on .NET 5",
      subtitle: "- Mehmet Ozkaya",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-fdc6574d-8736-4b05-9226-94a69c1b212b/",
      alt_name: "udemy",
      color_code: "#00bfff",
    },
    {
      title: "Microservices with Node JS and React",
      subtitle: "- Stephen Grider",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-66602a77-ee08-4bdb-990b-3b13dfc86da7/",
      alt_name: "Udemy",
      color_code: "#1F70C199",
    },
    {
      title: "Build an E-commerce app with .Net Core and Angular",
      subtitle: "- Neil Cummings",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-4d2ed507-8abd-44c6-8175-e189de193d4d/",
      alt_name: "udemy",
      color_code: "#0C9D5899",
    },
    {
      title: "Complete guide to building an app with .Net Core and React",
      subtitle: "- Neil Cummings",
      logo_path: "NetCoreandReact.jpg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-cccd4208-0b13-4948-a6e6-f8c976c1c204/",
      alt_name: "udemy",
      color_code: "#0C9D5899",
    },
    {
      title: "Build an app with ASPNET Core and Angular from scratch",
      subtitle: "- Neil Cummings",
      logo_path: "angular_core.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-99d1ea61-1b85-49d2-bf38-959896c9785b/",
      alt_name: "udemy",
      color_code: "#0C9D5899",
    },
    {
      title: "Complete React Developer in 2021 (w/ Redux, Hooks, GraphQL)",
      subtitle: "- Andrei Neagoie, Yihua Zhang",
      logo_path: "complete_react.jpg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-0cc805b2-daee-475f-9b3f-eca45ba84075/",
      alt_name: "udemy",
      color_code: "#0C9D5899",
    },
    {
      title: "The Modern Angular Bootcamp",
      subtitle: "- Stephen Grider",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-a7c18d84-c379-43a5-9c65-ede82d1e893b/",
      alt_name: "udemy",
      color_code: "#8C151599",
    },
    {
      title: "Angular Material Angularfire NgRx",
      subtitle: "- Maximilian Schwarzmüller",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-b6a1e509-188f-4f04-a079-54fe5d37be23/",
      alt_name: "angular material",
      color_code: "#00000099",
    },

    {
      title: "Algorithms and Data Structures C#",
      subtitle: "- Engineer Spock",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-51e79043-e92d-4d11-bcc6-fc5869a2797b/",
      alt_name: "Udemy",
      color_code: "#1F70C199",
    },
    {
      title: "Complete Next.js Developer in 2023: Zero to Mastery",
      subtitle: "- Andrei Neagoie, Ankita Kulkarni",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-66602a77-ee08-4bdb-990b-3b13dfc86da7/",
      alt_name: "Udemy",
      color_code: "#1F70C199",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked on many interesting projects from simple Single Page applications to as Complex as Enterprise Applications like Multi-vendor Ecommerce as a Front-end and Backend Developer in some cases also as a Software Architect. I am also working on some open-source projects. I also worked as a Freelance developer with some amazing clients from all over the world.",
  // description:
  //   "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Senior Software Engineer",
          company: "SmartConsult",
          company_url: "https://www.smartconsult.app/",
          logo_path: "smart-consult-logo.svg",
          duration: "July 2023 - Present",
          location: "Brisbane City, QLD, Australia · Remote",
          description:
            "Revolutionizing healthcare paperwork with AI. Transforming patient consultations into accurate notes, auto-generating key medical documents, and streamlining administrative tasks. Empowering doctors to focus more on patient care and less on paperwork. Join the future of healthcare today!",
          color: "#0879bf",
        },

        {
          title: "Freelance Software Engineer",
          company: "Travis Industries",
          company_url: "https://www.travisindustries.com/",
          logo_path: "travis.jpg",
          duration: "November 2022 - January 2023",
          location: "Mukilteo, Washington, United States · Remote",
          description:
            "The “us” is Travis Industries, the largest, privately-owned wood, pellet and gas stove, insert and fireplace company in America and encompasses four high-quality brands: Lopi™, Fireplace Xtrordinair™, DaVinci Custom Fireplaces™ and Fire Garden™. These brands are available exclusively from a network of more than 1,200 authorized retailers throughout the United States, Canada, Australia and China. All our products are manufactured in the United States within an 11-acre, Mukilteo, Washington  facility called the House of Fire, employing up to 600 employees just thirty minutes north of Seattle.",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "Virtual Upon AGM Solutions",
          company_url: "https://www.virtualupon.com/",
          logo_path: "virtualupon.png",
          duration: "January 2022 - July 2022",
          location: "Toronto, Ontario, Canada · Remote",
          description:
            "Corporate Governance, Electronic Voting, Notice Distribution Platform & Virtual Meetings for Condominiums & HOAs. Almost every AGM deals with a preliminary notice of the meeting,  electronic package delivery, candidate disclosure forms, proxy forms. motions, nomination, Q&A, voting,  and a meeting. We employ innovative and practical solutions to simplify these business processes. We provide an all-in-one solution designed to ease business challenges which will increase meeting participation, awareness and help your meetings reach a quorum.  Virtual Upon provides advance electronic proxy voting, virtual lAnnual General Meetings and other governance-related services. Our clients include condominiums and HOAs all over North America, regulatory bodies, trade associations, corporate shareholder and other entities with sophisticated voting requirements.  ",
          color: "#0879bf",
        },
        {
          title: "Software Engineer | Part Time",
          company: "Virtual Upon AGM Solutions",
          company_url: "https://www.virtualupon.com/",
          logo_path: "virtualupon.png",
          duration: "May 2021 - December 2021",
          location: "Toronto, Ontario, Canada · Remote",
          description:
            "Corporate Governance, Electronic Voting, Notice Distribution Platform & Virtual Meetings for Condominiums & HOAs. Almost every AGM deals with a preliminary notice of the meeting,  electronic package delivery, candidate disclosure forms, proxy forms. motions, nomination, Q&A, voting,  and a meeting. We employ innovative and practical solutions to simplify these business processes. We provide an all-in-one solution designed to ease business challenges which will increase meeting participation, awareness and help your meetings reach a quorum.  Virtual Upon provides advance electronic proxy voting, virtual lAnnual General Meetings and other governance-related services. Our clients include condominiums and HOAs all over North America, regulatory bodies, trade associations, corporate shareholder and other entities with sophisticated voting requirements.  ",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "Alchemy Software Limited",
          company_url: "https://alchemy-bd.com/",
          logo_path: "alchemy.jpg",
          duration: "November 2020 - December 2021",
          location:
            "Yahya Tower (6th Floor), Lalkhan Bazar, Chittagong, Bangladesh",
          description:
            "Alchemy Software is a growing IT service provider of high-end business solutions to clients across the globe. Over the years, the company extended the range of its services in various industries. As a software development company with a deep understanding of values and commitment, Alchemy Software believes that real profit for any business is the goodwill and it doesn’t come without customer’s satisfaction. Based on that philosophy, Alchemy Software devoted its full effort on customer satisfaction by providing standard software solutions. In combine cutting edged technology, merit, and innovation are three strengths for creating something extraordinary.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Recommendations",
      experiences: [
        {
          title: "Hasanul Awal Maruf ",
          company: "Assistant Manager at Infinia Group.",
          company_url: "https://www.linkedin.com/in/hamaruf/",
          logo_path: "Maruf.jpeg",
          duration: "",
          location: "",
          description:
            "Robin is a fantastic person to work with, very productive & hard-working as well as forward thinking individual. He is consistently delivering high quality works and very supportive to his co-worker. He is very focused and make sure that his work meets the highest standard. Robin is an great asset to any company.",
          color: "#ee3c26",
        },
        {
          title: "Shahril Ahmed Siddiquee",
          company: "Software Engineer at Alchemy Software Limited",
          company_url:
            "https://www.linkedin.com/in/shahril-ahmed-siddiquee-7568089a/",
          logo_path: "shahril.jpeg",
          duration: "",
          location: "",
          description:
            "I had the pleasure of working as a back-end developer under Mr. Haider Ali on a project and got the opportunity to come across a real talent and a great team leader. Incredible expertise in development is visible through his works. I was amazed by his way of writing code that was clean and well structured. I also found him deadline oriented. As I was a back-end developer in that project, he helped me understand what tasks were done and what were not by diligently ensuring that tasks were updated and tracked in sync with the front-end development. He encouraged me to learn new technologies and helped me on the way. Mr. Haider Ali is a very talented and professional full-stack developer and a team leader. He would be an asset to any company!",
          color: "#0071C5",
        },
        {
          title: "Sakib Hossain",
          company: "Software Engineer at Alchemy Software Limited",
          company_url: "https://www.linkedin.com/in/shfsakib/",
          logo_path: "sakib.jpeg",
          duration: "",
          location: "",
          description:
            "I have had the pleasure of knowing Haider Ali for the past. He is very thorough in everything he does and can be depended upon to get the job done. I would recommend him with any project that requires the very best in developement execution. He was always able to successfully complete any tasks with favorable results despite deadline pressure. Haider Ali possesses a winning combination of solid tech skills, I learned a lot from him. He is a truly remarkable person. He is one of the most valuable people I've ever worked with. He understands complicated issues even when outside of his direct area of expertise. For Haider Ali the job is obviously the most important thing to do. Haider Ali is a organized and customer-service oriented perfectionist, has no problem to work hard when necessary. He was always able to successfully complete any tasks with favorable results despite deadline pressure. For Haider Ali the job is always the most important thing to do. Very positive attitude towards work. He is an asset to any organisation.",
          color: "#0071C5",
        },
        {
          title: "Srinath Kanti Nath",
          company: "Software Engineer @ Alchemy Software Ltd.",
          company_url: "https://www.linkedin.com/in/hellosrinath/",
          logo_path: "srinath.jpeg",
          duration: "",
          location: "",
          description:
            "Md. Haider is a well connected professional that always takes the time to support anyone is his network. He has a very impressive background and profile and I recommend Md. Haider as a Technology expert to connect with and consider for anything appropriate.",
          color: "#0071C5",
        },
        {
          title: "lvalverde01",
          company: "Client from United States",
          company_url: "",
          logo_path: "person.jpeg",
          duration: "",
          location: "",
          description:
            "Absolutely! amazing delivery by Mr Halder. Always a joy to work with Mr Halder!! Not only does he delivers as promised but he also makes sure to explain the issue and the solution.",
          color: "#0071C5",
        },
        {
          title: "aliahmad8701",
          company: "Client from Hungary",
          company_url: "",
          logo_path: "person.jpeg",
          duration: "",
          location: "",
          description:
            "Robin is very professional in every area. It was a pleasure working with him. Everything went smooth as silk. Defiantly will be working with him again.",
          color: "#0071C5",
        },
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Explore ML Facilitator",
    //       company: "Google",
    //       company_url: "https://about.google/",
    //       logo_path: "google_logo.png",
    //       duration: "June 2019 - April 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Microsoft Student Partner",
    //       company: "Microsoft",
    //       company_url: "https://www.microsoft.com/",
    //       logo_path: "microsoft_logo.png",
    //       duration: "Aug 2019 - May 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "Mozilla Campus Captain",
    //       company: "Mozilla",
    //       company_url: "https://www.mozilla.org/",
    //       logo_path: "mozilla_logo.png",
    //       duration: "Oct 2019 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Developer Students Club Member",
    //       company: "DSC IIITDM Kurnool",
    //       company_url:
    //         "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //       logo_path: "dsc_logo.png",
    //       duration: "Jan 2018 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //       color: "#0C9D58",
    //     },
    //     {
    //       title: "Developer Program Member",
    //       company: "Github",
    //       company_url: "https://github.com/",
    //       logo_path: "github_logo.png",
    //       duration: "July 2019 - PRESENT",
    //       location: "Work From Home",
    //       description:
    //         "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating peoper documentation for project.",
    //       color: "#181717",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Web projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile.jpg",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ASP.NET Core, Angular, React, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://www.geekycoder.net/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Sitakund, Chattogram, Bangladesh",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/U81QPvKvZFC8uCkz6",
  },
  phoneSection: {
    title: "Discord",
    subtitle: "Robin Haider#1628",
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
